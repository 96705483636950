/**
 * @generated SignedSource<<e007dfce4a3aa8580d4908c1252f3bdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useIsSubscribed_authUser$data = {
  readonly isSubscribed: boolean;
  readonly " $fragmentType": "useIsSubscribed_authUser";
};
export type useIsSubscribed_authUser$key = {
  readonly " $data"?: useIsSubscribed_authUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"useIsSubscribed_authUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsSubscribed_authUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSubscribed",
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "0eb13a932d27fd688a7c3a9fe63b895e";

export default node;
