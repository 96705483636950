/**
 * @generated SignedSource<<1f06e62919e0dabc4eafc03d2163c9ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserType = "artist" | "partner";
import { FragmentRefs } from "relay-runtime";
export type useAuth_MeFragment$data = {
  readonly email: string;
  readonly id: string;
  readonly userType: UserType | null;
  readonly " $fragmentSpreads": FragmentRefs<"AuthUserAvatarName_authUser" | "ChatThread_authUser" | "FundPortfolioPage_authUser" | "Inbox_authUser" | "PrivateRoute_authUser" | "RowFund_userArtist" | "UserInfo_authUser" | "UserNav_authUser" | "useArtistCanViewDeals_authUser" | "useCanAccessMarketplace_authUser" | "useIsSubscribed_authUser" | "useOfferNextStep_authUser" | "usePartnerCanViewDeals_authUser" | "useUserCanViewChats_authUser">;
  readonly " $fragmentType": "useAuth_MeFragment";
};
export type useAuth_MeFragment$key = {
  readonly " $data"?: useAuth_MeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAuth_MeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAuth_MeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCanAccessMarketplace_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePartnerCanViewDeals_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useArtistCanViewDeals_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PrivateRoute_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserInfo_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthUserAvatarName_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatThread_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOfferNextStep_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Inbox_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RowFund_userArtist"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserNav_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FundPortfolioPage_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useIsSubscribed_authUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useUserCanViewChats_authUser"
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "f6c96e2a254eb86ede4784088c38e9b3";

export default node;
