import {
  useAuth_MeFragment$data,
  useAuth_MeFragment$key
} from "__generated__/useAuth_MeFragment.graphql";
import { useAuthActivateMutation } from "__generated__/useAuthActivateMutation.graphql";
import { useAuthLoginMutation } from "__generated__/useAuthLoginMutation.graphql";
import { useAuthLogoutMutation } from "__generated__/useAuthLogoutMutation.graphql";
import { useAuthMeQuery as useAuthMeQueryType } from "__generated__/useAuthMeQuery.graphql";
import { useAuthPasswordResetMutation } from "__generated__/useAuthPasswordResetMutation.graphql";
import {
  configureScope as configureSentryScope,
  setUser as setSentryUser
} from "@sentry/react";
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState
} from "react";
import {
  commitMutation,
  graphql,
  PreloadedQuery,
  useFragment,
  usePreloadedQuery
} from "react-relay";

import environment from "../../../environment";

export interface IProvideAuth {
  authUser: useAuth_MeFragment$data;
  setMeFragmentRef: (fragmentRef: useAuth_MeFragment$key) => void;
  signIn: (email: string, password: string) => Promise<void>;
  activate: (activationKey: string, password: string) => Promise<void>;
  passwordReset: (email: string) => Promise<void>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<IProvideAuth>(null);

interface IProvideAuthProps {
  queryRef: PreloadedQuery<useAuthMeQueryType>;
  children: ReactNode;
}

// Provider component that wraps the app and makes auth object available to
// any child component that calls useAuth().
export function ProvideAuth(props: IProvideAuthProps): ReactElement {
  const data = usePreloadedQuery<useAuthMeQueryType>(meQuery, props.queryRef);

  const [meFragmentRef, setMeFragmentRef] = useState<useAuth_MeFragment$key>(
    data.me
  );

  const loginData = useFragment(
    graphql`
      fragment useAuth_MeFragment on UserNode {
        id
        email
        userType
        ...useCanAccessMarketplace_authUser
        ...usePartnerCanViewDeals_authUser
        ...useArtistCanViewDeals_authUser
        ...PrivateRoute_authUser
        ...UserInfo_authUser
        ...AuthUserAvatarName_authUser
        ...ChatThread_authUser
        ...useOfferNextStep_authUser
        ...Inbox_authUser
        ...RowFund_userArtist
        ...UserNav_authUser
        ...FundPortfolioPage_authUser
        ...useIsSubscribed_authUser
        ...useUserCanViewChats_authUser
      }
    `,
    meFragmentRef
  );

  const signIn = (email: string, password: string) => {
    return new Promise<void>((resolve, reject) => {
      commitMutation<useAuthLoginMutation>(environment, {
        mutation: graphql`
          mutation useAuthLoginMutation($email: String, $password: String) {
            login(email: $email, password: $password) {
              user {
                id
                email
                name
                ...useAuth_MeFragment
              }
              ok
            }
          }
        `,
        variables: {
          email,
          password
        },
        onCompleted: response => {
          if (!response.login.ok) {
            return reject();
          }

          const authUser = response.login.user;
          setMeFragmentRef(authUser);
          setSentryUser({
            id: authUser.id,
            email: authUser.email,
            username: authUser.name
          });

          return resolve();
        },
        onError: error => reject(error)
      });
    });
  };

  const activate = (activationKey: string, password: string) => {
    return new Promise<void>((resolve, reject) => {
      commitMutation<useAuthActivateMutation>(environment, {
        mutation: graphql`
          mutation useAuthActivateMutation(
            $activationKey: String
            $password: String
          ) {
            activate(activationKey: $activationKey, password: $password) {
              user {
                ...useAuth_MeFragment
              }
              ok
            }
          }
        `,
        variables: {
          activationKey,
          password
        },
        onCompleted: response => {
          setMeFragmentRef(response.activate.user);
          return resolve();
        },
        onError: error => reject(error)
      });
    });
  };

  const passwordReset = (email: string) => {
    return new Promise<void>((resolve, reject) => {
      commitMutation<useAuthPasswordResetMutation>(environment, {
        mutation: graphql`
          mutation useAuthPasswordResetMutation($email: String) {
            passwordReset(email: $email) {
              ok
            }
          }
        `,
        variables: { email },
        onCompleted: () => resolve(),
        onError: error => reject(error)
      });
    });
  };

  const signOut = () => {
    return new Promise<void>((resolve, reject) => {
      commitMutation<useAuthLogoutMutation>(environment, {
        mutation: graphql`
          mutation useAuthLogoutMutation {
            logout {
              ok
            }
          }
        `,
        variables: {},
        onCompleted: () => {
          configureSentryScope(scope => scope.setUser(null));
          location.reload();

          resolve();
        },
        onError: error => reject(error)
      });
    });
  };

  // Return the user object and auth methods
  const auth = {
    authUser: loginData,
    setMeFragmentRef,
    signIn,
    signOut,
    passwordReset,
    activate
  };

  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = (): IProvideAuth => {
  return useContext(AuthContext);
};

export const meQuery = graphql`
  query useAuthMeQuery {
    me {
      ...useAuth_MeFragment
    }
  }
`;
