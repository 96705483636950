/**
 * @generated SignedSource<<6c7dfcdac242462d9dcee458fbc3be95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ManageSubscription_user$data = {
  readonly availableSubscriptions: ReadonlyArray<{
    readonly id: string | null;
    readonly period: string | null;
    readonly price: number | null;
  } | null> | null;
  readonly isSubscribed: boolean;
  readonly stripeCustomerId: string | null;
  readonly " $fragmentType": "ManageSubscription_user";
};
export type ManageSubscription_user$key = {
  readonly " $data"?: ManageSubscription_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ManageSubscription_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ManageSubscription_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSubscribed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeCustomerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceOption",
      "kind": "LinkedField",
      "name": "availableSubscriptions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "period",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserNode",
  "abstractKey": null
};

(node as any).hash = "a3db25c433a7c844f097a34cce043f7e";

export default node;
