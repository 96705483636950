import { useIsSubscribed_authUser$key } from "__generated__/useIsSubscribed_authUser.graphql";
import { graphql, useFragment } from "react-relay";

import { useAuth } from "./useAuth";

export default function useIsSubscribed(): boolean {
  const authUser = useFragment<useIsSubscribed_authUser$key>(
    graphql`
      fragment useIsSubscribed_authUser on UserNode {
        isSubscribed
      }
    `,
    useAuth().authUser
  );

  if (!authUser) {
    return false;
  }

  return authUser.isSubscribed;
}
