import {
  ManageSubscription_user$data,
  ManageSubscription_user$key
} from "__generated__/ManageSubscription_user.graphql";
import { ReactElement, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { Text } from "theme-ui";

import AutoLayout, {
  FillContainer
} from "../../../../components/01_Core/AutoLayout";
import LoadingButton from "../../../../components/01_Core/Buttons/LoadingButton";
import { useStripeCheckoutRedirect } from "../../hooks/useStripeCheckoutRedirect";
import { useStripePortalRedirect } from "../../hooks/useStripePortalRedirect";
import BlockLowEmphasis from "../03_UI_Kit/Marketing/BlockLowEmphasis";
import AccountManagementFormContent from "./AccountManagementFormContent";
import AccountManagementHeading from "./AccountManagementHeading";

interface ISubscriptionActionProps {
  onClick: () => void;
  loading: boolean;
  buttonText: string;
}

function SubscriptionAction(props: ISubscriptionActionProps) {
  return (
    <LoadingButton
      onClick={props.onClick}
      variant={"primary"}
      size={"large"}
      loading={props.loading}
      disabled={false}
      sx={{ width: "100%" }}
    >
      {props.buttonText}
    </LoadingButton>
  );
}

function ManageSubscription(props: {
  user: ManageSubscription_user$key;
}): ReactElement {
  const user = useFragment(
    graphql`
      fragment ManageSubscription_user on UserNode {
        isSubscribed
        stripeCustomerId
        availableSubscriptions {
          id
          price
          period
        }
      }
    `,
    props.user
  );

  const [selectedSubscription, setSelectedSubscription] = useState<
    ManageSubscription_user$data["availableSubscriptions"][0]
  >(user.availableSubscriptions[0]);

  const [checkoutRedirect, isCheckoutRedirecting] = useStripeCheckoutRedirect(
    selectedSubscription.period,
    window.location.href
  );
  const [portalRedirect, isPortalRedirecting] = useStripePortalRedirect(
    window.location.href
  );

  const IsSubscribed = () => {
    return (
      <AutoLayout
        spacing={32}
        dependentProps={{ direction: "vertical" }}
        resizingX={FillContainer()}
      >
        <BlockLowEmphasis size={["small", "small", "large", "large"]}>
          You are currently subscribed to indify. Click the button below to
          manage your subscription.
        </BlockLowEmphasis>
        <SubscriptionAction
          onClick={portalRedirect}
          loading={isPortalRedirecting}
          buttonText={"Manage your subscription"}
        />
      </AutoLayout>
    );
  };
  const IsNotSubscribed = () => {
    return (
      <AutoLayout
        spacing={16}
        dependentProps={{ direction: "vertical" }}
        resizingX={FillContainer()}
      >
        <BlockLowEmphasis size={["small", "small", "large", "large"]}>
          You are not currently subscribed to indify. Select a billing interval
          and click the button below to subscribe.
        </BlockLowEmphasis>
        <AutoLayout
          spacing={8}
          dependentProps={{ direction: "horizontal" }}
          resizingX={FillContainer()}
          mb={"20px"}
        >
          {user.availableSubscriptions.map(subscription => (
            <AutoLayout
              key={subscription.id}
              spacing={4}
              dependentProps={{ direction: "vertical" }}
              resizingX={FillContainer()}
              p={"16px"}
              onClick={() => setSelectedSubscription(subscription)}
              sx={{
                cursor: "pointer",
                border: "1px solid",
                borderColor: theme =>
                  selectedSubscription.id === subscription.id
                    ? theme.colors.black100
                    : theme.colors.midGray70,
                backgroundColor: theme =>
                  selectedSubscription.id === subscription.id
                    ? theme.colors.lightGray100
                    : "inherit",
                borderRadius: "4px",
                ":hover": {
                  borderColor: theme =>
                    selectedSubscription.id === subscription.id
                      ? "inherit"
                      : theme.colors.midGray100
                }
              }}
            >
              <AutoLayout
                spacing={4}
                dependentProps={{
                  direction: "horizontal",
                  alignment: "bottomCenter"
                }}
              >
                <Text variant={"subtitle"}>${subscription.price}</Text>
                <Text variant={"bodyMedium"}>/ {subscription.period}</Text>
              </AutoLayout>
              <Text variant={"bodyMedium"}>Billed {subscription.period}ly</Text>
              <Text variant={"bodyMedium"}>14 day free trial</Text>
            </AutoLayout>
          ))}
        </AutoLayout>
        <SubscriptionAction
          onClick={checkoutRedirect}
          loading={isCheckoutRedirecting}
          buttonText={"Continue to Payment"}
        />
      </AutoLayout>
    );
  };

  return (
    <AccountManagementFormContent>
      <AccountManagementHeading
        key={"heading"}
        size={["small", "large", "large", "large"]}
        title={"Subscription Settings"}
        description={"Manage your subscription to indify."}
      />
      {user.isSubscribed && user.stripeCustomerId ? (
        <IsSubscribed />
      ) : (
        <IsNotSubscribed />
      )}
    </AccountManagementFormContent>
  );
}

export default ManageSubscription;
