import { UserNav_authUser$key } from "__generated__/UserNav_authUser.graphql";
import { Fragment, ReactElement, useEffect } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { Box, Flex } from "theme-ui";

import Ic20Close from "../../../../../../imgs/icons/ic20-close.svg";
import AutoLayout, {
  FillContainer
} from "../../../../../components/01_Core/AutoLayout";
import Divider from "../../../../../components/01_Core/Miscelleneous/Divider";
import { Tooltip } from "../../../../../components/01_Core/Tooltips/Tooltip";
import AuthUserAvatarName from "../../../../../components/01_Core/Users/AuthUserAvatarName";
import { Logo } from "../../../../../components/02_Brand_Assets/Logo";
import { zIndices } from "../../../../../theme/theme";
import { lockBodyScroll, unlockBodyScroll } from "../../../../../utils/utils";
import useArtistCanViewDeals from "../../../hooks/useArtistCanViewDeals";
import { useAuth } from "../../../hooks/useAuth";
import useCanAccessMarketplace from "../../../hooks/useCanAccessMarketplace";
import useIsSubscribed from "../../../hooks/useIsSubscribed";
import usePartnerCanViewDeals from "../../../hooks/usePartnerCanViewDeals";
import useUserCanViewChats from "../../../hooks/useUserCanViewChats";
import { ExternalRoutes } from "../../../Routing/ExternalRoutes";
import { InternalRoutes } from "../../../Routing/InternalRoutes";
import {
  basicInfoSubpage,
  dealsSubpage
} from "../../08_Account_Management/AccountManagementPage";
import UserNavItem from "./UserNavItem";

interface IUserNavProps {
  close: () => void;
  useSmallNav: boolean;
}

/**
 * figma:
 *   - https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1359%3A241
 *   - https://www.figma.com/file/Q4dKaolSHBVeKqTFqEzuvv/03-UI-Kit?node-id=1359%3A242
 */
function UserNav(props: IUserNavProps): ReactElement {
  if (props.useSmallNav) {
    return <UserNavSmall close={props.close} />;
  } else {
    return <UserNavLarge />;
  }
}

function UserNavSmall(props: { close: () => void }): ReactElement {
  const { signOut, authUser: authUserQuery } = useAuth();
  const authUser = useFragment<UserNav_authUser$key>(
    graphql`
      fragment UserNav_authUser on UserNode {
        userType
      }
    `,
    authUserQuery
  );
  const userType = authUser.userType;

  const isSubscribed = useIsSubscribed();
  const canAccessMarketplace = useCanAccessMarketplace();
  const [partnerCanViewDeals, partnerCannotViewDealsText] =
    usePartnerCanViewDeals();
  const [artistCanViewDeals, artistCannotViewDealsText] =
    useArtistCanViewDeals();
  const [userCanViewChats, _] = useUserCanViewChats();

  useEffect(() => {
    lockBodyScroll();

    return unlockBodyScroll;
  }, []);

  const UserTypeNavItems = () => {
    return userType === "artist" ? (
      <Fragment>
        {canAccessMarketplace && (
          <UserNavItem
            route={generatePath(InternalRoutes.settings, {
              subpage: dealsSubpage
            })}
            size={"small"}
          >
            Propose a Deal
          </UserNavItem>
        )}
        {canAccessMarketplace && (
          <UserNavItem
            route={generatePath(InternalRoutes.settings, {
              subpage: "releases"
            })}
            size={"small"}
          >
            Add Upcoming Release
          </UserNavItem>
        )}
        {canAccessMarketplace && (
          <UserNavItem route={InternalRoutes.fundDirectory} size={"small"}>
            Browse Partners
          </UserNavItem>
        )}
        <UserNavItem
          route={InternalRoutes.insights}
          badge={"BETA"}
          size={"small"}
        >
          Insights
        </UserNavItem>
        <Tooltip content={artistCannotViewDealsText} size={"medium"}>
          <UserNavItem
            route={InternalRoutes.portfolio}
            size={"small"}
            disabled={!artistCanViewDeals}
          >
            Portfolio
          </UserNavItem>
        </Tooltip>
      </Fragment>
    ) : (
      <Fragment>
        <UserNavItem
          route={InternalRoutes.featuredDealDirectory}
          size={"small"}
        >
          Deals
        </UserNavItem>
        <UserNavItem route={InternalRoutes.artistDirectory} size={"small"}>
          Artists
        </UserNavItem>
        <UserNavItem route={InternalRoutes.fundDirectory} size={"small"}>
          Partners
        </UserNavItem>
        <Tooltip content={partnerCannotViewDealsText} size={"medium"}>
          <UserNavItem
            route={InternalRoutes.portfolio}
            size={"small"}
            disabled={!partnerCanViewDeals}
          >
            Portfolio
          </UserNavItem>
        </Tooltip>
      </Fragment>
    );
  };

  return (
    <Box
      bg={"white100"}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: zIndices.nav,
        overflowY: "auto"
      }}
    >
      <Flex
        px={"8px"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          height: "57px"
        }}
      >
        <Logo />
        <Ic20Close
          color={"black100"}
          onClick={props.close}
          style={{ cursor: "pointer" }}
        />
      </Flex>
      <Divider />
      <AutoLayout
        spacing={32}
        resizingX={FillContainer()}
        dependentProps={{
          direction: "vertical"
        }}
        padding={"32px 32px 0 32px"}
      >
        <AuthUserAvatarName size={"large"} />
        <Divider />
      </AutoLayout>
      <AutoLayout
        spacing={0}
        resizingX={FillContainer()}
        dependentProps={{
          direction: "vertical"
        }}
        padding={"24px 1px"}
      >
        <UserTypeNavItems />
        {userCanViewChats && (
          <UserNavItem
            route={generatePath(InternalRoutes.inbox)}
            size={"small"}
          >
            Inbox
          </UserNavItem>
        )}
        <UserNavItem
          route={generatePath(InternalRoutes.settings, {
            subpage: basicInfoSubpage
          })}
          size={"small"}
        >
          Update profile
        </UserNavItem>
        <UserNavItem route={ExternalRoutes.help} external={true} size={"small"}>
          Help Center
        </UserNavItem>
        <UserNavItem size={"small"} onClick={signOut}>
          Sign out
        </UserNavItem>
      </AutoLayout>
    </Box>
  );
}

function UserNavLarge(): ReactElement {
  const { signOut } = useAuth();

  return (
    <Box
      bg={"white100"}
      sx={{
        position: "absolute",
        top: 64,
        right: 0,
        boxShadow: "0px 12px 40px 0px #1B1B1B1A",
        cursor: "default",
        borderRadius: "4px",
        border: "1px solid",
        borderColor: "midGray100",
        width: "328px",
        zIndex: zIndices.nav,
        // This pseudo-element provides 30 pixels of hover area around the dropdown menu itself to protect
        // the user from accidentally dismissing the menu too easily, as well as to account for the delta between
        // the hover origin & the menu itself.
        "::after": {
          top: -30,
          right: 0,
          content: '""',
          position: "absolute",
          height: "100%",
          width: "100%",
          py: 30,
          paddingLeft: 30,
          paddingRight: 0,
          zIndex: zIndices.hidden
        }
      }}
    >
      <Box padding={"32px"}>
        <AuthUserAvatarName size={"large"} />
      </Box>
      <Divider />
      <Box py={"24px"}>
        <UserNavItem
          route={generatePath(InternalRoutes.settings, {
            subpage: basicInfoSubpage
          })}
          size={"large"}
        >
          Update profile
        </UserNavItem>
        <UserNavItem route={ExternalRoutes.help} external={true} size={"large"}>
          Help Center
        </UserNavItem>
        <UserNavItem size={"large"} onClick={signOut}>
          Sign out
        </UserNavItem>
      </Box>
    </Box>
  );
}

export default UserNav;
