import { ReactElement } from "react";
import { generatePath, Redirect } from "react-router-dom";

import { UserType } from "../../../types/users";
import { subscriptionSubpage } from "../components/08_Account_Management/AccountManagementPage";
import { useAuth } from "../hooks/useAuth";
import useIsSubscribed from "../hooks/useIsSubscribed";
import { InternalRoutes } from "./InternalRoutes";

function DefaultPrivatePage(): ReactElement {
  const { authUser } = useAuth();

  const isSubscribed = useIsSubscribed();

  if (!authUser) {
    return (
      <Redirect
        to={{
          pathname: InternalRoutes.login,
          state: { from: location.pathname }
        }}
      />
    );
  }

  if (!isSubscribed) {
    return (
      <Redirect
        to={generatePath(InternalRoutes.settings, {
          subpage: subscriptionSubpage
        })}
      />
    );
  }

  const defaultRoutes: {
    [userType in UserType]: (typeof InternalRoutes)[keyof typeof InternalRoutes];
  } = {
    artist: InternalRoutes.insights,
    partner: InternalRoutes.featuredDealDirectory
  };

  return <Redirect to={defaultRoutes[authUser.userType]} />;
}

export default DefaultPrivatePage;
